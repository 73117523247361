const createErrorsObject = (fields) => {
  return Object.keys(fields).reduce((newObject, field) => {
    newObject[field] = fields[field].join(",");

    return newObject;
  }, {});
};

const setErrorsFields = {
  graphql(setErrors, onError) {
    onError(({ graphQLErrors }) => {
      if (graphQLErrors && graphQLErrors[0]?.extensions?.fields) {
        setErrors(createErrorsObject(graphQLErrors[0].extensions.fields));
      }
    });
  },

  rest(setErrors, response) {
    if (response.errors?.extensions?.fields) {
      setErrors(createErrorsObject(response.errors.extensions.fields));
    }
  },
};

export { setErrorsFields };
